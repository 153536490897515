
@import url("https://cdn.barques.co.uk/sites/barques/assets/fonts/fonts.min.css?ver=5.0.3");

body.login {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    font-family: SuisseIntl,Helvetica Neue,Helvetica,Arial,sans-serif;
    -webkit-font-smoothing: antialiased;
    background-color: #edf5f8;
    background: radial-gradient(circle at 0 100%,rgba(0,155,223,.99),rgb(19, 40, 76));

    #login {
        padding: 36px 12px;
        box-shadow: none;
        width: 100%;
        max-width: 420px;

        #loginform {
            background-color: transparent;
            box-shadow: none;

            label {
                color: #edf5f8;
                text-decoration: none;
                font-size: 14px;
                line-height: 1.75;
                font-weight: 600;
            }

            #user_login, #user_pass {
                font-size: 16px;
                line-height: 2.4;
                border: 0;
                width: 100%;
                background-color: #EDEDED;
                color: #464646;
            }

            input[type=checkbox] {
                background-color: transparent;
                appearance: none;
                border-color: white;
                position: relative;

                &:checked:before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    margin: 0;
                    color: white;
                    outline-color: white !important;
                    content: "";
                    background-color: white;
                    width: 100%;
                    height: 100%;
                    border-color: white;
                }

                &:hover, &:focus {
                    outline: none !important;
                }
            }

            .forgetmenot {
                label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    user-select: none;
                }
            }
        }

        h1 {
            a {
                background-image: url("https://cdn.barques.co.uk/sites/barques/assets/images/logo/barques_logo_white.svg");
                background-size: 90px !important;
                height: 90px !important;
                width: 90px !important;
                outline: 0;
                border: 0;
                box-shadow: none;
                margin: 40px auto 60px;
            }
        }

        #nav {
            margin-top: 40px;
        }

        #backtoblog, #nav, h1 {
            a {
                color: #edf5f8;
                font-size: 14px;

                &:hover {
                    color: #edf5f8;
                    text-decoration: underline;
                }
            }
        }

        input[type=text], input[type=password], input[type=checkbox], input[type=color], input[type=date], input[type=datetime], input[type=datetime-local], input[type=email], input[type=month], input[type=number], input[type=radio], input[type=tel], input[type=time], input[type=url], input[type=week], input[type=search], select, textarea, input[type=checkbox]:checked:before {
            outline-color: #43B3A2 !important;
        }

        .button {
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
            -webkit-border-radius: 0 !important;
            -moz-border-radius: 0 !important;
            border-radius: 0 !important;
            color: white !important;
            text-shadow: none;

            &:hover {
                background-color: #EDEDED !important;
                border-color: #EDEDED !important;
                color: #464646!important;
            }
        }
    }
}
